/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Rois";
  src: url("/assets/font/Rois-Medium.woff2") format("woff2"),
    url("/assets/font/Rois-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HeadlineGothic";
  src: url("/assets/font/headline_gothic_atf.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Carneys";
  src: url("/assets/font/Carneys-Gallery.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Code Pro";
  src: url("assets/font/SourceCodePro-Regular.woff2") format("woff2"),
    url("assets/font/SourceCodePro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Coolvetica";
  src: url("assets/font/coolvetica-rg.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RobotoMono";
  src: url("assets/font/RobotoMono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.sarabun {
  font-family: "Sarabun", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.source-font {
  font-family: "Source Code Pro", sans-serif;
}

.roi-font {
  font-family: "Rois", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: black;
}

.headline-font {
  font-family: "HeadlineGothic", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.coolvetica-font {
  font-family: "Coolvetica", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.robotomono-font {
  font-family: "RobotoMono", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
}

.stretch {
  width: 100%;
  height: 100%;
}

.modal-close-button {
  position: absolute; 
  top: 15px; 
  right: 15px;
  background-color: black;
}